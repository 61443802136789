@font-face {
  font-family: "galano-grotesque";
  src: url("./assets//fonts/GalanoGrotesqueBold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "galano-grotesque";
  src: url("./assets//fonts/GalanoGrotesqueExtraBold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "galano-grotesque";
  src: url("./assets//fonts/GalanoGrotesqueExtraLight.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "galano-grotesque";
  src: url("./assets//fonts/GalanoGrotesqueHeavy.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "galano-grotesque";
  src: url("./assets//fonts/GalanoGrotesqueLight.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "galano-grotesque";
  src: url("./assets//fonts/GalanoGrotesqueMedium.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "galano-grotesque";
  src: url("./assets//fonts/GalanoGrotesqueRegular.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "galano-grotesque";
  src: url("./assets//fonts/GalanoGrotesqueSemiBold.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "galano-grotesque";
  src: url("./assets//fonts/GalanoGrotesqueThin.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}
* {
  font-family: "galano-grotesque", sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap");
