.link {
  font-size: 24px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
  transition: all 0.03s;
  @media (min-width: 900px) and (max-width: 1295px) {
    font-size: 18px;
  }
  @media (min-width: 0) and (max-width: 900px) {
    font-size: 20px;
  }
  // @media (min-width: 0) and (max-width: 601px) {
  //   font-size: 32px;
  // }
}
.active {
  color: #3a643b;
  font-weight: 700;
  position: relative;
  transition: all 0.03s;
  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #3a643b;
    position: absolute;
    top: 40px;
  }
  @media (min-width: 900px) and (max-width: 1295px) {
    &::before {
      width: 6px;
      height: 6px;
      top: 30px;
    }
  }
  @media (min-width: 0) and (max-width: 900px) {
    &::before {
      width: 8px;
      height: 8px;
      top: 35px;
    }
  }
}
